import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { FormItemCreateRequest, FormListDataResponse, FormListResponse } from '@/models/forms';
import { ToastProgrammatic } from 'buefy';

@Module({
    namespaced: true,
    dynamic: true,
    name: `forms.create`,
    store,
})
class FormCreateStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    formName: string = '';
    response: FormListDataResponse | null = null;
    
    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setFormName(payload: string) {
        this.formName = payload;
    }

    @Mutation
    setResponse(payload: FormListDataResponse | null) {
        this.response = payload;
    }

    @Action
    createNewForm(data: FormItemCreateRequest) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        this.context.commit(`setFormName`, data.formName);
        axios.post<FormListResponse>(`/form`, {
            subjectId: data.subjectId,
            formName: data.formName,
        }).then((response) => {
            if (response.data.code === 200) {
                ToastProgrammatic.open({
                    type: `is-success`,
                    duration: 3000, 
                    message: `Form successfully created!`,
                });
                this.context.commit(`setResponse`, response.data.data);
            } 
            
            if (response.data.code === 500) {
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with form creation. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }
}

export default getModule(FormCreateStore);