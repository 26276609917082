




























































































































































































































import { Vue, Component, Emit, Prop, Watch } from 'vue-property-decorator';
import Dropdown from '@/components/common/Dropdown.vue';
import MultipleChoiceTemplate from '@/components/question/templates/MultipleChoiceTemplate.vue';
import EssayTemplate from '@/components/question/templates/EssayTemplate.vue';
import {
  GeneralEssayQuestionBody,
  GeneralMCQQuestionBody,
  GeneralQuestionBankQuestionBody,
  UpdateQuestionBankEssayQuestionRequest,
  UpdateQuestionBankMCQQuestionRequest,
  UpdateQuestionBankQuestions,
} from '@/models/question_banks';
import {
  AcceptedImageFileType,
  AcceptedVideoFileType,
  MaximumMCQInputs,
  MinimumMCQInputs,
  QuestionTypeNames,
} from '@/utils/constants';
import { DropdownItem } from '@/models/dropdown';
import { EssayItem, MultipleChoiceItem } from '@/models/miscellaneous';
import Upload from '@/components/common/Upload.vue';
import { UploadAttachmentWithThumbnail } from '@/models/generic';
// TinyMCE and MathType Implementation
import Editor from '@tinymce/tinymce-vue';
// We explicitly declare like this to enable tinymce to work
(window.globalThis as any).tinymce = require('tinymce');
require(`@wiris/mathtype-tinymce5`);
@Component({
  components: {
    Dropdown,
    Editor,
    MultipleChoiceTemplate,
    EssayTemplate,
    Upload,
  },
})
export default class QuestionFormTemplate extends Vue {
  minimumMCQInputs = MinimumMCQInputs;
  maximumMCQInputs = MaximumMCQInputs;
  // Category Dropdowns
  selectedQuestionSubject = '- Select -';
  selectedQuestionLevel = '- Select -';
  selectedQuestionBankList = '- Select -';
  selectedQuestionChapter = '- Select -';
  isQuestionLevelDropdownDisabled = true;
  isQuestionBankListDropdownDisabled = true;
  isQuestionChapterDropdownDisabled = true;
  // Question Name
  selectedIsAvailable = true;
  selectedQuestionName = ``;
  selectedQuestionAsset: UploadAttachmentWithThumbnail | null = null;
  selectedQuestionText = ``;
  selectedDefaultMark = ``;
  selectedGeneralFeedback = ``;
  selectedShuffleChoices: boolean = false;
  selectedOneOrMultipleAnswers = '- Select -';
  selectedNumberTheChoices = '- Select -';
  selectedStandardInstructions = '- Select -';
  // General Form Data
  currentGeneralQuestionBody: GeneralQuestionBankQuestionBody = {
    id: -1,
    subjectId: -1,
    formId: -1,
    questionBankId: -1,
    chapterId: -1,
    questionType: ``,
    availability: true,
    questionName: ``,
    questionAsset: ``,
    questionText: ``,
    defaultMark: -1,
    generalFeedback: ``,
  };
  // MCQ
  currentMCQQuestionBody: GeneralMCQQuestionBody = {
    areChoicesShuffled: false,
    hasMultipleAnswers: null,
    choiceNumberType: '',
    showStandardInstructions: null,
    choices: [],
  };
  currentMCQInputs: MultipleChoiceItem[] = [];
  //Essay
  currentEssayQuestionBody: GeneralEssayQuestionBody = {
    answerText: ``,
    requireText: ``,
  };
  currentEssayInput: EssayItem = {
    answerText: ``,
    requireText: ``,
  };
  isCurrentFormValid = false;
  questionTextImage: File | null = null;

  // Edit Variables
  previousSelectedQuestionSubject = ``;
  previousSelectedQuestionLevel = ``;
  previousSelectedQuestionBankList = ``;
  previousSelectedQuestionChapter = ``;
  previousSelectedAssetUrl = ``;
  previousSelectedAnswerOption = ``;
  previousSelectedAnswerDisplay = ``;
  previousSelectedStandardInstruction = ``;

  @Prop()
  currentQuestionData!: UpdateQuestionBankQuestions;

  @Prop({ default: false })
  isEditMode!: boolean;

  @Prop({ required: true })
  questionSubjectData!: DropdownItem[];

  @Prop({ required: true })
  questionLevelData!: DropdownItem[];

  @Prop({ required: true })
  questionBankListingData!: DropdownItem[];

  @Prop({ required: true })
  questionChapterData!: DropdownItem[];

  @Prop({ required: true })
  questionAnswerOptionData!: DropdownItem[];

  @Prop({ required: true })
  questionAnswerDisplayData!: DropdownItem[];

  @Prop({ required: true })
  questionStandardInstructionData!: DropdownItem[];

  @Prop()
  questionSubjectPrefixId!: number;

  @Prop()
  questionLevelPrefixId!: number;

  @Prop()
  questionBankListPrefixId!: number;

  @Prop()
  questionChapterPrefixId!: number;

  @Prop()
  selectedQuestionType!: QuestionTypeNames;

  get subjects() {
    return this.questionSubjectData;
  }

  get levels() {
    return this.questionLevelData;
  }

  get questionBankListings() {
    return this.questionBankListingData;
  }

  get chapters() {
    return this.questionChapterData;
  }

  get answerSelectionOptions() {
    return this.questionAnswerOptionData;
  }

  get answerDisplayOptions() {
    return this.questionAnswerDisplayData;
  }

  get answerStandardInstructionData() {
    return this.questionStandardInstructionData;
  }

  get currentType(): QuestionTypeNames {
    this.currentGeneralQuestionBody.questionType = this.selectedQuestionType;
    return this.selectedQuestionType;
  }

  get isCurrentTypeMCQ() {
    return this.isEditMode
      ? 'hasMultipleAnswers' in this.currentQuestionData
      : this.currentType === QuestionTypeNames.mcq;
  }

  get isCurrentTypeEssay() {
    return this.isEditMode
      ? 'answerText' in this.currentQuestionData
      : this.currentType === QuestionTypeNames.essay;
  }

  get assetFileTypes() {
    return [...AcceptedImageFileType, ...AcceptedVideoFileType];
  }

  private get TinyMceAPIKey() {
    return process.env.VUE_APP_TINY_MCE_API_KEY;
  }

  get QuestionTextConfig() {
    return {
      height: 300,
      menubar: false,
      external_plugins: {
        tiny_mce_wiris: `node_modules/@wiris/mathtype-tinymce5/plugin.min.js`,
      },
      plugins: [
        'advlist autolink lists link image charmap print preview anchor',
        'searchreplace visualblocks code fullscreen',
        'insertdatetime media table paste code help wordcount',
      ],
      toolbar:
        'undo redo | formatselect | bold italic backcolor |\
                alignleft aligncenter alignright alignjustify | \
                bullist numlist outdent indent | removeformat | help |\
                tiny_mce_wiris_formulaEditor | tiny_mce_wiris_formulaEditorChemistry',
      htmlAllowedTags: ['.*'],
      htmlAllowedAttrs: ['.*'],
      draggable_modal: true,
    };
  }

  beforeCreate() {
    const script = document.createElement(`script`);
    script.src = 'https://www.wiris.net/demo/plugins/app/WIRISplugins.js?viewer=image';
    document.head.appendChild(script);
  }

  mounted() {
    if (!this.isEditMode) {
      for (let index = 0; index < MinimumMCQInputs; index++) {
        this.currentMCQInputs.push({
          id: index + 1,
          choiceText: ``,
          feedbackText: ``,
          isAnswerValid: false,
        });
      }
    }

    if (this.isEditMode && this.currentQuestionData) {
      this.repopulateQuestionData();
    }
  }

  @Watch(`questionSubjectPrefixId`)
  autofillSubjectSelection() {
    if (this.questionSubjectPrefixId > -1) {
      this.previousSelectedQuestionSubject = this.questionSubjectPrefixId.toString();
    }
  }

  @Watch(`questionLevelPrefixId`)
  autofillLevelSelection() {
    if (this.questionLevelPrefixId > -1) {
      this.previousSelectedQuestionLevel = this.questionLevelPrefixId.toString();
    }
  }

  @Watch(`questionBankListPrefixId`)
  autofillQuestionBankListSelection() {
    if (this.questionBankListPrefixId > -1) {
      this.previousSelectedQuestionBankList = this.questionBankListPrefixId.toString();
    }
  }

  @Watch(`questionChapterPrefixId`)
  autofillChapterSelection() {
    if (this.questionChapterPrefixId > -1) {
      this.previousSelectedQuestionChapter = this.questionChapterPrefixId.toString();
    }
  }

  repopulateQuestionData() {
    // After checking, we can fill in. This only applies for Edit
    if (this.currentQuestionData) {
      this.currentGeneralQuestionBody = this.currentQuestionData;
      this.previousSelectedQuestionSubject = this.currentGeneralQuestionBody.subjectId.toString();
      this.previousSelectedQuestionLevel = this.currentGeneralQuestionBody.formId.toString();
      this.previousSelectedQuestionBankList = this.currentGeneralQuestionBody.questionBankId.toString();
      this.previousSelectedQuestionChapter = this.currentGeneralQuestionBody.chapterId.toString();
      this.selectedQuestionName = this.currentGeneralQuestionBody.questionName;
      this.selectedQuestionText = this.currentGeneralQuestionBody.questionText;
      this.selectedDefaultMark = this.currentGeneralQuestionBody.defaultMark.toString();
      this.selectedGeneralFeedback = this.currentGeneralQuestionBody.generalFeedback;
      this.selectedIsAvailable = this.currentGeneralQuestionBody.availability;
      this.previousSelectedAssetUrl = this.currentGeneralQuestionBody.questionAsset as string;

      // This property is specifically for MCQ
      if ('hasMultipleAnswers' in this.currentQuestionData) {
        this.selectedShuffleChoices = this.currentQuestionData.areChoicesShuffled as boolean;
        this.previousSelectedAnswerOption = this.currentQuestionData.hasMultipleAnswers
          ? 'multiple'
          : 'one';
        this.previousSelectedAnswerDisplay = this.currentQuestionData.choiceNumberType;
        this.previousSelectedStandardInstruction = this.currentQuestionData.showStandardInstructions
          ? 'yes'
          : 'no';
        this.currentMCQInputs = [];
        this.currentQuestionData.choices.forEach((data) => {
          this.currentMCQInputs.push({
            id: data.id,
            choiceText: data.choiceText,
            isAnswerValid: data.isAnswerValid,
            feedbackText: data.feedbackText,
          });
        });
      }

      // This property is specifically for Essay
      if ('answerText' in this.currentQuestionData) {
        this.currentEssayInput = {
          answerText: this.currentQuestionData.answerText,
          requireText: this.currentQuestionData.requireText,
        };
      }
    }
  }

  // Increase Functions
  increaseChoiceCount() {
    this.currentMCQInputs.push({
      id: this.currentMCQInputs.length + 1,
      choiceText: ``,
      isAnswerValid: false,
      feedbackText: ``,
    });
  }

  decreaseChoiceCount(item: MultipleChoiceItem) {
    // Reset
    const tempStorage: MultipleChoiceItem[] = [];
    this.currentMCQInputs
      .filter((data) => {
        return data.id !== item.id;
      })
      .forEach((data, index) => {
        data.id = index + 1;
        tempStorage.push(data);
      });
    this.currentMCQInputs = tempStorage;
  }

  // Update Functions
  updateQuestionSubjectState(data: DropdownItem) {
    this.selectedQuestionSubject = data.text;
    this.currentGeneralQuestionBody.subjectId = parseInt(data.value);
    this.isQuestionLevelDropdownDisabled = false;
  }

  updateQuestionSubject(data: DropdownItem) {
    this.updateQuestionSubjectState(data);
    this.isQuestionLevelDropdownDisabled = true;
    this.validateCurrentForm();
    if (this.currentGeneralQuestionBody.subjectId > 0) {
      this.isQuestionLevelDropdownDisabled = false;
      this.isQuestionChapterDropdownDisabled = true;
      this.isQuestionBankListDropdownDisabled = true;
      this.selectedQuestionLevel = '- Select -';
      this.selectedQuestionBankList = '- Select -';
      this.selectedQuestionChapter = '- Select -';
      this.currentGeneralQuestionBody.formId = 0;
      this.currentGeneralQuestionBody.questionBankId = 0;
      this.currentGeneralQuestionBody.chapterId = 0;
      this.previousSelectedQuestionLevel = '';
      this.previousSelectedQuestionBankList = '';
      this.previousSelectedQuestionChapter = '';
      this.retrieveLevelDropdown();
    }
  }

  updateQuestionLevelState(data: DropdownItem) {
    this.selectedQuestionLevel = data.text;
    this.currentGeneralQuestionBody.formId = parseInt(data.value);
    this.isQuestionBankListDropdownDisabled = false;
  }

  updateQuestionLevel(data: DropdownItem) {
    this.updateQuestionLevelState(data);
    this.isQuestionBankListDropdownDisabled = true;
    this.isQuestionChapterDropdownDisabled = true;
    this.validateCurrentForm();
    if (this.currentGeneralQuestionBody.formId > 0) {
      this.isQuestionChapterDropdownDisabled = true;
      this.isQuestionBankListDropdownDisabled = false;
      this.selectedQuestionBankList = '- Select -';
      this.selectedQuestionChapter = '- Select -';
      this.currentGeneralQuestionBody.questionBankId = 0;
      this.currentGeneralQuestionBody.chapterId = 0;
      this.previousSelectedQuestionBankList = '';
      this.previousSelectedQuestionChapter = '';
      this.retrieveQuestionBankLevelDropdown();
    }
  }

  updateQuestionBankListState(data: DropdownItem) {
    this.selectedQuestionBankList = data.text;
    this.currentGeneralQuestionBody.questionBankId = Number(data.value);
    this.isQuestionChapterDropdownDisabled = false;
  }

  updateQuestionBankList(data: DropdownItem) {
    this.updateQuestionBankListState(data);
    this.isQuestionChapterDropdownDisabled = true;
    if (this.currentGeneralQuestionBody.formId > 0) {
      this.isQuestionChapterDropdownDisabled = false;
      this.selectedQuestionChapter = '- Select -';
      this.currentGeneralQuestionBody.chapterId = 0;
      this.previousSelectedQuestionChapter = '';
      this.retrieveChapterDropdown();
    }
  }

  updateQuestionChapterState(data: DropdownItem) {
    this.selectedQuestionChapter = data.text;
    this.currentGeneralQuestionBody.chapterId = parseInt(data.value);
  }

  updateQuestionChapter(data: DropdownItem) {
    this.updateQuestionChapterState(data);
    this.validateCurrentForm();
  }

  updateIsAvailable(payload: boolean) {
    this.selectedIsAvailable = payload;
    this.currentGeneralQuestionBody.availability = payload;
    this.validateCurrentForm();
  }

  updateQuestionText(data: string) {
    this.selectedQuestionText = data;
    this.currentGeneralQuestionBody.questionText = data;
    this.validateCurrentForm();
  }

  updateQuestionName(data: string) {
    this.selectedQuestionName = data;
    this.currentGeneralQuestionBody.questionName = data;
    this.validateCurrentForm();
  }

  updateQuestionAsset(data: UploadAttachmentWithThumbnail | null) {
    this.selectedQuestionAsset = data;
    this.currentGeneralQuestionBody.questionAsset = data;
    this.validateCurrentForm();
  }

  updateDefaultMark(data: string) {
    this.selectedDefaultMark = data;
    this.currentGeneralQuestionBody.defaultMark = parseInt(data);
    this.validateCurrentForm();
  }

  updateGeneralFeedback(data: string) {
    this.selectedGeneralFeedback = data;
    this.currentGeneralQuestionBody.generalFeedback = data;
    this.validateCurrentForm();
  }

  // MCQ Functions
  updateShuffleChoices(value: boolean) {
    this.selectedShuffleChoices = value;
    this.currentMCQQuestionBody.areChoicesShuffled = value;
    this.validateCurrentForm();
  }

  updateAnswerSelection(data: DropdownItem) {
    this.selectedOneOrMultipleAnswers = data.text;
    this.currentMCQQuestionBody.hasMultipleAnswers = data.value === 'multiple';
    this.validateCurrentForm();
  }

  updateAnswerDisplay(data: DropdownItem) {
    this.selectedNumberTheChoices = data.text;
    this.currentMCQQuestionBody.choiceNumberType = data.value;
    this.validateCurrentForm();
  }

  updateStandardInstructions(data: DropdownItem) {
    this.selectedStandardInstructions = data.text;
    this.currentMCQQuestionBody.showStandardInstructions = data.value === 'yes';
    this.validateCurrentForm();
  }

  updateMultipleChoiceItems(data: MultipleChoiceItem) {
    const index = this.currentMCQQuestionBody.choices.findIndex((item) => {
      return item.id === data.id;
    });
    if (index > -1) {
      this.currentMCQQuestionBody.choices[index] = data;
    } else {
      this.currentMCQQuestionBody.choices.push(data);
    }
    this.validateCurrentForm();
  }

  // Essay
  updateEssayInput(data: EssayItem) {
    this.currentEssayQuestionBody.answerText = data.answerText;
    this.validateCurrentForm();
  }

  // Validation
  validateCurrentForm() {
    this.isCurrentFormValid = true;
    // Check for MCQ Choices first
    if (this.currentType === QuestionTypeNames.mcq) {
      this.validateChoices();
    }

    // MCQ Specific Section
    if (
      this.currentType === QuestionTypeNames.mcq &&
      (this.currentMCQQuestionBody.areChoicesShuffled === null ||
        this.currentMCQQuestionBody.hasMultipleAnswers === null ||
        this.currentMCQQuestionBody.choiceNumberType.length < 1 ||
        this.currentMCQQuestionBody.showStandardInstructions === null ||
        this.currentMCQQuestionBody.choices.length < MinimumMCQInputs)
    ) {
      this.isCurrentFormValid = false;
    }

    // Essay Specific Section
    if (
      this.currentType === QuestionTypeNames.essay &&
      this.currentEssayQuestionBody.answerText.length < 1
    ) {
      this.isCurrentFormValid = false;
    }

    // General Section
    if (
      this.currentGeneralQuestionBody.chapterId < 1 ||
      this.currentGeneralQuestionBody.formId < 1 ||
      this.currentGeneralQuestionBody.chapterId < 1 ||
      this.currentGeneralQuestionBody.questionName.length < 1 ||
      this.currentGeneralQuestionBody.questionText.length < 1 ||
      this.currentGeneralQuestionBody.defaultMark < 1 ||
      this.currentGeneralQuestionBody.generalFeedback.length < 1
    ) {
      this.isCurrentFormValid = false;
    }
  }

  // MCQ specific validation
  validateChoices() {
    if (this.currentMCQQuestionBody.hasMultipleAnswers) {
      this.isCurrentFormValid = true;
      const hasMultipleAnswers = this.currentMCQQuestionBody.hasMultipleAnswers;

      let numberOfTrueAnswers = 0;
      this.currentMCQQuestionBody.choices.forEach((data) => {
        if (data.isAnswerValid) {
          numberOfTrueAnswers++;
        }
        if (data.choiceText.length < 1 || data.feedbackText.length < 1) {
          this.isCurrentFormValid = false;
        }
      });
      if (
        numberOfTrueAnswers === 0 ||
        (!hasMultipleAnswers && numberOfTrueAnswers > 1) ||
        (hasMultipleAnswers && numberOfTrueAnswers <= 1)
      ) {
        this.isCurrentFormValid = false;
      }
    }
  }

  // Emits
  @Emit(`retrieveLevels`)
  retrieveLevelDropdown() {
    return this.currentGeneralQuestionBody.subjectId;
  }

  @Emit(`retrieveQuestionBankListByFormId`)
  retrieveQuestionBankLevelDropdown() {
    return this.currentGeneralQuestionBody.formId;
  }

  @Emit(`retrieveChaptersByQuestionBankId`)
  retrieveChapterDropdown() {
    return this.currentGeneralQuestionBody.questionBankId;
  }

  @Emit(`update`)
  updateCurrentSource() {
    switch (this.currentType) {
      case QuestionTypeNames.mcq: {
        const output: UpdateQuestionBankMCQQuestionRequest = {
          ...this.currentGeneralQuestionBody,
          ...this.currentMCQQuestionBody,
        };
        return output;
      }
      case QuestionTypeNames.essay: {
        const output: UpdateQuestionBankEssayQuestionRequest = {
          ...this.currentGeneralQuestionBody,
          ...this.currentEssayQuestionBody,
        };
        return output;
      }
      default: {
        break;
      }
    }
  }

  redirectToQuestionManagement() {
    this.$router.push({
      path: `/question_banks`,
    });
  }
}
