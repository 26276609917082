import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { SubjectListDataResponse, SubjectListResponse } from '@/models/subjects';
import { ToastProgrammatic } from 'buefy';

@Module({
    namespaced: true,
    dynamic: true,
    name: `subjects.create`,
    store,
})
class SubjectCreateStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    subjectName: string = '';
    response: SubjectListDataResponse | null = null;
    
    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setSubjectName(payload: string) {
        this.subjectName = payload;
    }

    @Mutation
    setResponse(payload: SubjectListDataResponse | null) {
        this.response = payload;
    }

    @Action
    createNewSubject(subjectName: string) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        this.context.commit(`setSubjectName`, subjectName);
        axios.post<SubjectListResponse>(`/subject`, {
            subjectName: subjectName,
        }).then((response) => {
            if (response.data.code === 200) {
                ToastProgrammatic.open({
                    type: `is-success`,
                    duration: 3000, 
                    message: `Subject successfully created!`,
                });
                this.context.commit(`setResponse`, response.data.data);
            } 
            
            if (response.data.code === 500) {
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with subject creation. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }
}

export default getModule(SubjectCreateStore);