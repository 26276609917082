import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { SubjectItemUpdateRequest, SubjectListResponse } from '@/models/subjects';
import { ToastProgrammatic } from 'buefy';

@Module({
    namespaced: true,
    dynamic: true,
    name: `subjects.detail`,
    store,
})
class SubjectDetailStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    response: SubjectListResponse | null = null;
    currentSubject: SubjectItemUpdateRequest | null = null;
    
    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: SubjectListResponse | null) {
        this.response = payload;
    }

    @Mutation
    setCurrentSubject(payload: SubjectItemUpdateRequest | null) {
        this.currentSubject = payload;
    }

    @Action
    updateCurrentSubject(payload: SubjectItemUpdateRequest) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setCurrentSubject`, payload);
        this.context.commit(`setResponse`, null);
        axios.put<SubjectListResponse>(`/subject/${payload.id}`, {
            subjectName: payload.subjectName
        }).then((response) => {
            if (response.data.code === 200) {
                ToastProgrammatic.open({
                    type: `is-success`,
                    duration: 3000, 
                    message: `Subject successfully update!`,
                });
                this.context.commit(`setResponse`, response.data);
            } 
            
            if (response.data.code === 500) {
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with subject update process. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    deleteCurrentSubject(payload: SubjectItemUpdateRequest) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setCurrentSubject`, payload);
        this.context.commit(`setResponse`, null);
        axios.delete<SubjectListResponse>(`/subject/${payload.id}`).then((response) => {
            if (response.data.code === 200) {
                ToastProgrammatic.open({
                    type: `is-info`,
                    duration: 3000, 
                    message: `Subject successfully deleted!`,
                });
                this.context.commit(`setResponse`, response.data);
            } 
            if (response.data.code === 500) {
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with subject delete process. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }
}

export default getModule(SubjectDetailStore);