import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { FormItemUpdateRequest, FormListResponse } from '@/models/forms';
import { ToastProgrammatic } from 'buefy';

@Module({
    namespaced: true,
    dynamic: true,
    name: `forms.detail`,
    store,
})
class FormDetailStore extends VuexModule {
    isLoading = false;
    error: any | null = null;
    response: FormListResponse | null = null;
    currentForm: FormItemUpdateRequest | null = null;
    
    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: FormListResponse | null) {
        this.response = payload;
    }

    @Mutation
    setCurrentForm(payload: FormItemUpdateRequest | null) {
        this.currentForm = payload;
    }

    @Action
    updateCurrentForm(payload: FormItemUpdateRequest) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setCurrentForm`, payload);
        this.context.commit(`setResponse`, null);
        axios.put<FormListResponse>(`/form/${payload.id}`, {
            formName: payload.formName
        }).then((response) => {
            if (response.data.code === 200) {
                ToastProgrammatic.open({
                    type: `is-success`,
                    duration: 3000, 
                    message: `Form successfully update!`,
                });
                this.context.commit(`setResponse`, response.data);
            }
            
            if (response.data.code === 500) {
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with form update process. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }

    @Action
    deleteCurrentForm(payload: FormItemUpdateRequest) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setCurrentForm`, payload);
        this.context.commit(`setResponse`, null);
        axios.delete<FormListResponse>(`/form/${payload.id}`).then((response) => {
            if (response.data.code === 200) {
                ToastProgrammatic.open({
                    type: `is-info`,
                    duration: 3000, 
                    message: `Form successfully deleted!`,
                });
                this.context.commit(`setResponse`, response.data);
            } 
            
            if (response.data.code === 500) {
                ToastProgrammatic.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with form delete process. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, false);
    }
}

export default getModule(FormDetailStore);