import {
    Action,
    getModule,
    Module,
    Mutation,
    VuexModule,
} from 'vuex-module-decorators';
import store from '@/store/store';
import { axios } from '@/configs/axios';
import { ChapterListResponse, CreateChapterDetails } from '@/models/chapters';
import { ToastProgrammatic as Toast } from 'buefy';
@Module({
    namespaced: true,
    dynamic: true,
    name: `chapters.create`,
    store,
})
class ChapterCreateStore extends VuexModule{
    isLoading = false;
    error: any | null = null;
    response: boolean | null = null;
    createData: CreateChapterDetails | null = null;

    @Mutation
    setError(payload: any) {
        this.error = payload;
    }

    @Mutation
    updateLoadingStatus(payload: boolean) {
        this.isLoading = payload;
    }

    @Mutation
    setResponse(payload: boolean) {
        this.response = payload;
    }

    @Mutation
    setCreateData(payload: CreateChapterDetails) {
        this.createData = payload;
    }

    @Action
    createNewChapter(payload: CreateChapterDetails) {
        this.context.commit(`updateLoadingStatus`, true);
        this.context.commit(`setError`, null);
        this.context.commit(`setResponse`, null);
        this.context.commit(`setCreateData`, payload);
        axios.post<ChapterListResponse>('/chapter', payload).then((response) => {
            if (response.data.code === 200) {
                this.context.commit(`setResponse`, response.data.data);
                Toast.open({
                    type: `is-success`,
                    duration: 3000, // 3 Seconds
                    message: `Chapter successfully created`,
                });
            } 
            
            if (response.data.code === 500) {
                Toast.open({
                    type: `is-danger`,
                    duration: 3000, // 3 Seconds
                    message: `There is an issue with creating a new chapter. Please try again`,
                });
            }
        }).catch((error) => {
            this.context.commit(`setError`, error);
        });
        this.context.commit(`updateLoadingStatus`, true);
    }
}

export default getModule(ChapterCreateStore);