

































import { Vue, Component, Watch } from 'vue-property-decorator';
import Page from '@/layouts/Page.vue';
import QuestionsLayout from '@/layouts/question/QuestionsLayout.vue';
import QuestionFormTemplate from '@/components/question/QuestionFormTemplate.vue';
import { CreateQuestionBankQuestions, CreateQuestionBankQuestionForAxios } from '@/models/question_banks';
import { FormStores } from '@/store/forms';
import { SubjectStores } from '@/store/subjects';
import { BasicYesOrNo, NumberChoices, OneOrMultipleAnswers, QuestionTypeNames, VideoFileSizeLimit } from '@/utils/constants';
import { DropdownItem } from '@/models/dropdown';
import { ChapterStores } from '@/store/chapters';
import { QuestionStores } from '@/store/questions';
import { ToastProgrammatic } from 'buefy';
import { QuestionBankStores } from '@/store/question_banks';
import { uploadAttachment } from '@/utils/attachments';

@Component({
    components: { Page, QuestionsLayout, QuestionFormTemplate },
})
export default class AddQuestion extends Vue {
    subjectListStore = SubjectStores.list;
    levelListStore = FormStores.list;
    chapterListStore = ChapterStores.list;
    questionCreateStore = QuestionStores.create;
    questionStateStore = QuestionStores.state;
    questionBankListStore = QuestionBankStores.list;
    // Dropdowns
    questionSubjectList: DropdownItem[] = [];
    questionLevelList: DropdownItem[] = [];
    questionBankList: DropdownItem[] = [];
    questionChapterList: DropdownItem[] = [];
    answerOptionList = OneOrMultipleAnswers;
    answerDisplayList = NumberChoices;
    standardInstructionList = BasicYesOrNo;
    // Autofill variables
    questionSubjectPrefixId = -1;
    questionLevelPrefixId = -1;
    isQuestionBankListAutofillAllowed = false;
    questionBankListPrefixId = -1;
    questionChapterPrefixId = -1;
    // Form Selection Variable
    selectedQuestionType: QuestionTypeNames | string = '';

    currentFormTemplate: CreateQuestionBankQuestions = {
        questionType: ``,
        availability: false,
        subjectId: -1,
        formId: -1,
        questionBankId: -1,
        chapterId: -1,
        questionName: '',
        questionText: ``,
        defaultMark: -1,
        generalFeedback: ``,
        answerText: ``,
        requireText: ``,
        questionAsset: null,
    };

    get title() {
        return `New Question`;
    }

    get subjects() {
        return this.questionSubjectList;
    }

    get levels() {
        return this.questionLevelList;
    }

    get questionBanks() {
        return this.questionBankList;
    }

    get chapters() {
        return this.questionChapterList;
    }

    get answerOptions() {
        return this.answerOptionList;
    }

    get answerDisplays() {
        return this.answerDisplayList;
    }

    get standardInstructions() {
        return this.standardInstructionList;
    }

    mounted() {
        switch(this.questionStateStore.questionBankManagementFolderState.length) {
            case 2: {
                this.questionSubjectPrefixId = this.questionStateStore.questionBankManagementFolderState[this.questionStateStore.questionBankManagementFolderState.length - 1].id;
                this.retrieveLevels(this.questionSubjectPrefixId);
                break;
            }
            case 3: {
                this.questionSubjectPrefixId = this.questionStateStore.questionBankManagementFolderState[this.questionStateStore.questionBankManagementFolderState.length - 2].id;
                this.questionLevelPrefixId = this.questionStateStore.questionBankManagementFolderState[this.questionStateStore.questionBankManagementFolderState.length - 1].id;
                this.retrieveLevels(this.questionSubjectPrefixId);
                this.retrieveQuestionBankListByFormId(this.questionLevelPrefixId);
                break;
            }
            case 4: {
                this.questionSubjectPrefixId = this.questionStateStore.questionBankManagementFolderState[this.questionStateStore.questionBankManagementFolderState.length - 3].id;
                this.questionLevelPrefixId = this.questionStateStore.questionBankManagementFolderState[this.questionStateStore.questionBankManagementFolderState.length - 2].id;
                this.questionChapterPrefixId = this.questionStateStore.questionBankManagementFolderState[this.questionStateStore.questionBankManagementFolderState.length - 1].id;
                this.retrieveLevels(this.questionSubjectPrefixId);
                this.retrieveQuestionBankListByFormId(this.questionLevelPrefixId);
                
                // Using this specific chapter id, we will need to find the question bank
                this.isQuestionBankListAutofillAllowed = true;
                this.questionBankListStore.retrieveQuestionBanks({ formid: this.questionLevelPrefixId, chapterid: this.questionChapterPrefixId });
                break;
            }
            default: {
                break;
            }
        }
        this.subjectListStore.retrieveSubjects({});
    }

    retrieveLevels(subjectId: number) {
        this.levelListStore.retrieveForms({ subjectid: subjectId });
    }

    retrieveQuestionBankListByFormId(formId: number) {
        this.questionBankListStore.retrieveQuestionBanks({ formid: formId });
    }

    retrieveChaptersByQuestionBankId(questionBankId: number) {
        // Update Question Type
        const index = this.questionBankListStore.response!.objects.findIndex((data) => {
            return data.id === questionBankId
        });
        if (index > -1) {
            this.selectedQuestionType = this.questionBankListStore.response!.objects[index].questionType;
        }
        this.chapterListStore.retrieveChaptersByQuestionBankId(questionBankId);
    }

    @Watch(`subjectListStore.response`)
    updateSubjectDropdown() {
        if (this.subjectListStore.response) {
            this.questionSubjectList = [];
            this.subjectListStore.response.objects.forEach((item) => {
                this.questionSubjectList.push({
                    text: item.subjectName,
                    value: item.id.toString(),
                    uniqueKey: `${item.id}_`
                });
            });
        }
    }

    @Watch(`levelListStore.response`)
    updateFormDropdown() {
        if (this.levelListStore.response) {
            this.questionLevelList = [];
            this.levelListStore.response.objects.forEach((item) => {
                this.questionLevelList.push({
                    text: item.formName,
                    value: item.id.toString(),
                    uniqueKey: `${item.id}_`
                });
            });
        }
    }

    @Watch(`questionBankListStore.response`)
    updateQuestionBanks() {
        if (this.questionBankListStore.response) {
            this.questionBankList = [];
            this.questionBankListStore.response.objects.forEach((data) => {
                if (data.questionType === QuestionTypeNames.mcq) {
                    this.questionBankList.push({
                        text: `${data.name} (Multiple Choice)`,
                        value: data.id.toString(),
                        uniqueKey: `${data.name}_${data.id}`,
                    });
                }

                if (data.questionType === QuestionTypeNames.essay) {
                    this.questionBankList.push({
                        text: `${data.name} (Essay)`,
                        value: data.id.toString(),
                        uniqueKey: `${data.name}_${data.id}`,
                    });
                }
            });
            // If the user selects until chapters, it means there will only be 1 question bank item
            if (this.questionStateStore.questionBankManagementFolderState.length === 4 && this.isQuestionBankListAutofillAllowed) {  
                // Find chapter first and map it back
                const chapterIndex = this.chapterListStore.response!.objects.findIndex((data) => {
                    return data.id === this.questionStateStore.questionBankManagementFolderState[this.questionStateStore.questionBankManagementFolderState.length - 1].id;
                });              
                if (chapterIndex > -1) {
                    const index = this.questionBankList.findIndex((data) => {
                        return Number(data.value) === this.chapterListStore.response!.objects[chapterIndex].questionbankid;
                    });
                    if (index > -1) {
                        this.questionBankListPrefixId = Number(this.questionBankList[index].value);
                        this.isQuestionBankListAutofillAllowed = false;
                        this.retrieveChaptersByQuestionBankId(this.questionBankListPrefixId);
                    }
                }
            }
        }
    }

    @Watch(`chapterListStore.response`)
    updateChapterDropdown() {
        if (this.chapterListStore.response) {
            this.questionChapterList = [];
            this.chapterListStore.response.objects.forEach((item) => {
                this.questionChapterList.push({
                    text: item.name,
                    value: item.id.toString(),
                    uniqueKey: `${item.id}_`
                });
            });
        }
    }

    redirectToQuestionManagement() {
        this.$router.push({
            path: `/question_banks`
        });
    }

    // Create a question
    async updateCurrentSource(data: CreateQuestionBankQuestions) {
        this.currentFormTemplate = {
            ...data
        };

        // Format data to match API
        // General Section
        let input: Partial<CreateQuestionBankQuestionForAxios> = {
            subjectId: data.subjectId,
            formId: data.formId,
            chapterId: data.chapterId,
            questionBankId: data.questionBankId,
            availability: data.availability ? 1 : 0,
            qType: data.questionType,
            name: data.questionName,
            questionText: data.questionText,
            defaultMark: data.defaultMark,
            generalFeedback: data.generalFeedback,
        };

        // Check for attachments

        // If questionAsset is a video type
        if (data.questionAsset && 
            typeof data.questionAsset !== 'string' &&
            data.questionAsset.originalFile && 
            data.questionAsset.originalFile.type.includes(`video/`) &&
            data.questionAsset.thumbnail
        ) {
            const thumbnailPromise = uploadAttachment(
                data.questionAsset.thumbnail,
                data.questionAsset.thumbnail?.name.split(`.`)[0],
                `thumbnail`
            );
            const videoPromise = uploadAttachment(
                data.questionAsset.originalFile,
                data.questionAsset.originalFile.name,
                `video`,
                VideoFileSizeLimit.questions
            );
            const promises = await Promise.all([thumbnailPromise, videoPromise]);
            input.videoThumbnail = promises[0];
            input.videoUrl = promises[1];
            input.imageUrl = '';
        }

        if (data.questionAsset &&
            typeof data.questionAsset !== 'string' &&
            data.questionAsset.originalFile && 
            data.questionAsset.originalFile.type.includes(`image/`)
        ) {
            const image = await uploadAttachment(
                data.questionAsset.originalFile,
                data.questionAsset.originalFile?.name,
                `thumbnail`
            );
            input.imageUrl = image;
            input.videoThumbnail = '';
            input.videoUrl = '';
        }

        if (
            data.questionAsset && 
            typeof data.questionAsset !== 'string' &&
            !data.questionAsset.thumbnail &&
            !data.questionAsset.originalFile
        ) { 
            input.imageUrl = '';
            input.videoUrl = '';
            input.videoThumbnail = '';
        }
        
        // TODO: Set approved to be dynamic
        // Mcq
    
        if ('hasMultipleAnswers' in data) {
            input.single = data.hasMultipleAnswers ? 0 : 1; // 1 means true, 0 means false
            input.shuffleAnswers = data.areChoicesShuffled ? 1 : 0
            input.answerNumbering = data.choiceNumberType;
            input.approved = 0;
            input.responseRequired = 0;

            // Format Choices to match  the API
            let answers: string[] = [];
            let fraction: number[] = [];
            let feedback: string[] = [];

            let currentValidAnswers = 0;
            data.choices.forEach((item) => {
                if (item.isAnswerValid) {
                    currentValidAnswers++;
                }
            });

            data.choices.forEach((item) => {
                answers.push(item.choiceText);
                fraction.push(item.isAnswerValid ? 1/currentValidAnswers : 0);
                feedback.push(item.feedbackText);
            });

            input.answer = answers;
            input.fraction = fraction;
            input.feedback = feedback;
        } else {
            // Essay
            input.single = 1; // 1 means true, 0 means false
            input.approved = 0;
            input.shuffleAnswers = 0;
            input.answerNumbering = `abc`; // Filler
            input.responseRequired = data.requireText === '1' ? 1 : 0;
            input.answer = [
                data.answerText
            ];
            input.fraction = [1];
            input.feedback = [''];
        }

        this.questionCreateStore.createNewQuestionBankQuestion(input);
    }

    @Watch(`questionCreateStore.response`)
    checkResponse() {
        if (this.questionCreateStore.response) {
            ToastProgrammatic.open({
                type: `is-success`,
                duration: 3000,
                message: `Question successfully created. Returning to Question Bank Management`
            });
            this.questionStateStore.updateQuestionBankManagementIsModified(true);
            this.$router.push({
                path: `/question_banks`
            });
        }
    }
}
